/** @format */

import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import KeyValueRow from '../../components/key-value-row/key-value-row.component'
import ScreenPanel from '../../components/screen-panel/screen-panel.component'
import ScreenTitle from '../../components/screen-title/screen-title.component'
import { Server } from '../../components/server/server.component'
import styles from './ticker-details.module.css'
import { Button } from 'react-bootstrap'
import classNames from 'classnames'

const TickerDetailsScreen = () => {
  const { id } = useParams()
  const { tickers } = useContext(Server)
  const [data, setData] = useState()
  const [tick, setTick] = useState()

  useEffect(() => {
    if (data) {
      const score = data.score?.split(':')
      setTick({ id: data.id, scoreHome: score?.[0] || '0', scoreGuest: score?.[1] || '0' })
    }
  }, [data])

  useEffect(() => {
    tickers.get(id.replace('|', '§'), setData, console.error)
  }, [tickers, id])

  const send = () => {
    tickers.send(tick, setData)
  }

  if (data == null) {
    return null
  }
  return (
    <ScreenPanel>
      <ScreenTitle label={data.home + ' - ' + data.guest} />
      <KeyValueRow k='ID'>{data.id}</KeyValueRow>
      <KeyValueRow k='Letzte Nachricht'>{data.lastMessage}</KeyValueRow>

      <div className={styles.row}>
        <input placeholder='Score Home' value={tick?.scoreHome} onChange={({ target: { value } }) => setTick({ ...tick, scoreHome: value })} />
        <input placeholder='Score Guest' value={tick?.scoreGuest} onChange={({ target: { value } }) => setTick({ ...tick, scoreGuest: value })} />
      </div>
      <div className={styles.row}>
        <div className={classNames(styles.button, tick?.selectedTeam === data?.home ? styles.selected : '')} onClick={() => setTick({ ...tick, selectedTeam: data?.home })}>
          {data?.home}
        </div>
        <div className={classNames(styles.button, tick?.selectedTeam === data?.guest ? styles.selected : '')} onClick={() => setTick({ ...tick, selectedTeam: data?.guest })}>
          {data?.guest}
        </div>
      </div>
      <hr />
      <div className={styles.row}>
        <div className={classNames(styles.button, tick?.selectedType === 'info' ? styles.selected : '')} onClick={() => setTick({ ...tick, selectedType: 'info' })}>
          info
        </div>
        <div className={classNames(styles.button, tick?.selectedType === 'kickoff' ? styles.selected : '')} onClick={() => setTick({ ...tick, selectedType: 'kickoff' })}>
          kickoff
        </div>
        <div className={classNames(styles.button, tick?.selectedType === 'q2' ? styles.selected : '')} onClick={() => setTick({ ...tick, selectedType: 'q2' })}>
          q2
        </div>
      </div>
      <div className={styles.row}>
        <div className={classNames(styles.button, tick?.selectedType === 'halftime' ? styles.selected : '')} onClick={() => setTick({ ...tick, selectedType: 'halftime' })}>
          halftime
        </div>
        <div className={classNames(styles.button, tick?.selectedType === 'q3' ? styles.selected : '')} onClick={() => setTick({ ...tick, selectedType: 'q3' })}>
          q3
        </div>
        <div className={classNames(styles.button, tick?.selectedType === 'q4' ? styles.selected : '')} onClick={() => setTick({ ...tick, selectedType: 'q4' })}>
          q4
        </div>
        <div className={classNames(styles.button, tick?.selectedType === 'end' ? styles.selected : '')} onClick={() => setTick({ ...tick, selectedType: 'end' })}>
          end
        </div>
      </div>
      <div className={styles.row}>
        <div className={classNames(styles.button, tick?.selectedType === 'touchdown' ? styles.selected : '')} onClick={() => setTick({ ...tick, selectedType: 'touchdown' })}>
          touchdown
        </div>
        <div className={classNames(styles.button, tick?.selectedType === 'pat' ? styles.selected : '')} onClick={() => setTick({ ...tick, selectedType: 'pat' })}>
          pat
        </div>
        <div className={classNames(styles.button, tick?.selectedType === '2pc' ? styles.selected : '')} onClick={() => setTick({ ...tick, selectedType: '2pc' })}>
          2pc
        </div>
      </div>
      <div className={styles.row}>
        <div className={classNames(styles.button, tick?.selectedType === 'safety' ? styles.selected : '')} onClick={() => setTick({ ...tick, selectedType: 'safety' })}>
          safety
        </div>
        <div className={classNames(styles.button, tick?.selectedType === 'fg' ? styles.selected : '')} onClick={() => setTick({ ...tick, selectedType: 'fg' })}>
          fg
        </div>
      </div>
      <hr />
      <div className={styles.row}>
        <input value={tick?.text || ''} onChange={({ target: { value } }) => setTick({ ...tick, text: value })} />
        <Button onClick={send}>Send</Button>
      </div>
      <hr />
      {data?.messages?.map?.(m => (
        <>
          <div className={styles.row}>
            <div>{m.team}</div>
            <div>{m.time}</div>
            <div>{m.type}</div>
            <div> {m.score}</div>
          </div>
          <div className={styles.row}>
            <div></div>
            <div className={styles.f3}>{m.text}</div>
          </div>
        </>
      ))}
    </ScreenPanel>
  )
}

export default TickerDetailsScreen
