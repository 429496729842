import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

const config = {
  apiKey: "AIzaSyDueFhaWLkPugHc3XPTwTookvemlidsSbA",
  authDomain: "troisdorf-jets-app.firebaseapp.com",
  databaseURL: "https://troisdorf-jets-app.firebaseio.com",
  projectId: "troisdorf-jets-app",
  storageBucket: "troisdorf-jets-app.appspot.com",
  messagingSenderId: "243598977422",
  appId: "1:243598977422:web:d43d168d58a831fb0d1cdf"
};

initializeApp(config);

export const auth = getAuth();
export const firestore = getFirestore();

const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });
export const signInWithGoogle = () => signInWithPopup(auth, provider);
