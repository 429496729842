/** @format */

import { Outlet } from 'react-router-dom'
import PageHeader from '../components/page-header/page-header.component'
import styles from './layout.module.css'

const Layout = () => {
  return (
    <div className={styles.layout}>
      {/* A "layout route" is a good place to put markup you want to
          share across all the pages on your site, like navigation. */}
      <PageHeader />

      {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
      <Outlet />
    </div>
  )
}

export default Layout
