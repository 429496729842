/** @format */
import { lazy, Suspense } from 'react'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import Layout from './layout/layout'

import ServerProvider from './components/server/server.component'

import WaitDialog from './components/wait-dialog/wait-dialog.component'

import TickerDetailsScreen from './screens/ticker-details/ticker-details.screen'

import './App.css'

const HomeScreen = lazy(() => import('./screens/home/home.screen'))
const SignInScreen = lazy(() => import('./screens/signin/signin.screen'))
const UsersScreen = lazy(() => import('./screens/users/users.screen'))
const TeamsScreen = lazy(() => import('./screens/teams/teams.screen'))
const UserDetailsScreen = lazy(() => import('./screens/user-details/user-details.screen'))
const TeamDetailsScreen = lazy(() => import('./screens/team-details/team-details.screen'))
const NewsScreen = lazy(() => import('./screens/news/news.screen'))
const NewsDetailsScreen = lazy(() => import('./screens/news-details/news-details.screen'))
const TickerScreen = lazy(() => import('./screens/ticker/ticker.screen'))


function App() {
  return (
    <div className='App'>
      <ServerProvider>
        <Router>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route path='/' element={<HomeScreen />} />
                <Route path='signin' element={<SignInScreen />} />
                <Route path='news' element={<NewsScreen />} />
                <Route path='news/:id' element={<NewsDetailsScreen />} />
                <Route path='tickers' element={<TickerScreen />} />
                <Route path='tickers/:id' element={<TickerDetailsScreen />} />

                <Route path='teams' element={<TeamsScreen />} />
                <Route path='teams/:id' element={<TeamDetailsScreen />} />

                <Route path='users' element={<UsersScreen />} />
                <Route path='users/:id' element={<UserDetailsScreen />} />
              </Route>
            </Routes>
          </Suspense>
        </Router>
      </ServerProvider>
      <WaitDialog />
    </div>
  )
}

export default App
