/** @format */

import React, { createContext } from 'react'
import { auth } from '../../firebase/firebase.utils'
import { trackPromise } from 'react-promise-tracker'

export const Server = createContext()

export const baseURL = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : 'https://troisdorf-jets.cf/api'

var lastToken = null

function imagePathWithToken(name) {
  return `${baseURL}/articles/images/${name}?token=${lastToken}`
}

function executeGet(url, query, callback, errorCallback) {
  if (!auth.currentUser) {
    return
  }
  auth.currentUser.getIdToken().then(token => {
    lastToken = token
    var myHeaders = new Headers()
    myHeaders.append('Accept', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + token)

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    const _url = new URL(baseURL + url)
    if (query) {
      _url.search = new URLSearchParams(query)
    }
    trackPromise(
      fetch(_url, requestOptions)
        .then(response => {
          console.log('received', response)
          if (response.status === 200) {
            console.log('status 200')
            return response.json()
          }
          if (response.status === 204) {
            console.log('status 204')
            return {}
          }
          if (response.headers.get('Content-Type') === 'application/json') {
            console.log('error with json')
            return response.json().then(error => Promise.reject(error))
          }
          console.log('error without json')
          return response.text().then(text => Promise.reject({ message: text }))
        })
        .then(result => callback(result))
        .catch(error => {
          if (errorCallback) errorCallback(error)
        })
    )
  })
}

function executeQuery(type, query, callback, errorCallback) {
  if (query) executeGet('/' + type, { q: query }, callback, errorCallback)
  else executeGet('/' + type, null, callback, errorCallback)
}

function executePost(url, query, body, callback, errorCallback) {
  if (!auth.currentUser) {
    return
  }
  auth.currentUser.getIdToken().then(token => {
    lastToken = token
    console.log('executePost ' + url, body)
    var myHeaders = new Headers()
    myHeaders.append('Accept', 'application/json')
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + token)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
    }
    if (body) {
      requestOptions.body = JSON.stringify(body)
    }
    console.log('executePost ' + url, requestOptions.body)
    const _url = new URL(baseURL + url)
    if (query) {
      _url.search = new URLSearchParams(query)
    }
    trackPromise(
      fetch(_url, requestOptions)
        .then(response => {
          console.log('received', response)
          if (response.status === 200) {
            console.log('status 200')
            response.json().then(result => callback(result))
            return
          }
          if (response.status === 204) {
            console.log('status 204')
            callback({})
            return
          }
          if (response.headers.get('Content-Type') === 'application/json') {
            try {
              console.log('error with json')
              response
                .json()
                .then(error => errorCallback(error))
                .catch(error => errorCallback(error))
              return
            } catch (e) {
              console.log('+++++++++++++++++++++++++')
            }
          }
          console.log('error without json')
          response
            .text()
            .then(text => errorCallback({ message: text }))
            .catch(error => errorCallback(error))
        })
        .catch(error => {
          if (errorCallback) errorCallback(error)
        })
    )
  })
}

function executePostForm(url, form, callback, errorCallback) {
  if (!auth.currentUser) {
    return
  }
  auth.currentUser.getIdToken().then(token => {
    lastToken = token
    var myHeaders = new Headers()
    myHeaders.append('Accept', 'application/json')
    if (token) {
      myHeaders.append('Authorization', 'Bearer ' + token)
    }

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: form,
    }
    trackPromise(
      fetch(baseURL + url, requestOptions)
        .then(response => {
          if (response.status === 200) {
            response.json().then(result => callback(result))
            return
          }
          if (response.status === 204) {
            callback({})
            return
          }
          if (response.headers.get('Content-Type') === 'application/json') {
            try {
              response
                .json()
                .then(error => errorCallback(error))
                .catch(error => errorCallback(error))
              return
            } catch (e) {
              console.log('+++++++++++++++++++++++++')
            }
          }
          response
            .text()
            .then(text => errorCallback({ message: text }))
            .catch(error => errorCallback(error))
        })
        .catch(error => {
          if (errorCallback) errorCallback(error)
        })
    )
  })
}

function executePut(url, body, callback, errorCallback) {
  if (!auth.currentUser) {
    return
  }
  auth.currentUser.getIdToken().then(token => {
    var myHeaders = new Headers()
    myHeaders.append('Accept', 'application/json')
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + token)

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(body),
    }
    trackPromise(
      fetch(baseURL + url, requestOptions)
        .then(response => {
          console.log('received', response)
          if (response.status === 200) {
            console.log('status 200')
            return response.json()
          }
          if (response.status === 204) {
            console.log('status 204')
            return {}
          }
          if (response.headers.get('Content-Type') === 'application/json') {
            console.log('error with json')
            return response.json().then(error => Promise.reject(error))
          }
          console.log('error without json')
          return response.text().then(text => Promise.reject({ message: text }))
        })
        .then(result => callback(result))
        .catch(error => {
          if (errorCallback) errorCallback(error)
        })
    )
  })
}

function executeDelete(url, callback, errorCallback) {
  if (!auth.currentUser) {
    return
  }
  auth.currentUser.getIdToken().then(token => {
    lastToken = token
    var myHeaders = new Headers()
    if (token) {
      myHeaders.append('Authorization', 'Bearer ' + token)
    }

    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow',
    }
    trackPromise(
      fetch(baseURL + url, requestOptions)
        .then(response => {
          console.log('received', response)
          if (response.status === 200) {
            console.log('status 200')
            return {}
          }
          if (response.status === 204) {
            console.log('status 204')
            return {}
          }
          if (response.headers.get('Content-Type') === 'application/json') {
            console.log('error with json')
            return response.json().then(error => Promise.reject(error))
          }
          console.log('error without json')
          return response.text().then(text => Promise.reject({ message: text }))
        })
        .then(result => callback(result))
        .catch(error => {
          if (errorCallback) errorCallback(error)
        })
    )
  })
}

function getItem(type, id, callback, errorCallback) {
  executeGet(`/${type}/${id}`, null, callback, errorCallback)
}

function createItem(type, body, callback, errorCallback) {
  executePost(`/${type}`, null, body, callback, errorCallback)
}

function updateItem(type, id, body, callback, errorCallback) {
  executePut(`/${type}/${id}`, body, callback, errorCallback)
}

function deleteItem(type, id, callback, errorCallback) {
  executeDelete(`/${type}/${id}`, callback, errorCallback)
}

const ServerProvider = ({ children }) => {
  return (
    <Server.Provider
      value={{
        image: name => imagePathWithToken(name),
        news: {
          load: callback => executeQuery('news', null, callback, console.error),
          get: (id, callback) => getItem('news', id, callback, console.error),
          create: (body, callback, errorCallback) => createItem('news', body, callback, errorCallback),
          update: (id, body, callback, errorCallback) => updateItem('news', id, body, callback, errorCallback),
        },
        teams: {
          load: (callback, errorCallback) => executeQuery('teams', null, callback, errorCallback),
          get: (id, callback, errorCallback) => getItem('teams', id, callback, errorCallback),
          create: (body, callback, errorCallback) => createItem('teams', body, callback, errorCallback),
          update: (id, body, callback, errorCallback) => updateItem('teams', id, body, callback, errorCallback),
        },
        tickers: {
          load: callback => executeQuery('tickers', null, callback, console.error),
          get: (id, callback) => getItem('tickers', id, callback, console.error),
          send: (body, callback) => executePost('/tickers', {}, body, callback, console.error),
        },
        users: {
          query: (callback, errorCallback) => executeQuery('users', null, callback, errorCallback),
          get: (id, callback, errorCallback) => getItem('users', id, callback, errorCallback),
          delete: (id, callback, errorCallback) => deleteItem('users', id, callback, errorCallback),
          setRoles: (userId, body, callback, errorCallback) => executePost(`/users/${userId}`, null, body, callback, errorCallback),
          init: (userId, realName, callback, errorCallback) => executePost(`/users/${userId}/init`, null, { realName }, callback, errorCallback),
          password: (userId, password, callback, errorCallback) => executePost(`/users/${userId}/password`, null, { password }, callback, errorCallback),
        },
      }}>
      {children}
    </Server.Provider>
  )
}

export default ServerProvider
