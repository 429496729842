import React, { useState, useEffect } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { Link } from 'react-router-dom';
import { auth } from '../../firebase/firebase.utils';
import Restrict from '../restrict/restrict.component';
import { useNavigate } from "react-router-dom";

import logo from '../../Logo-Jets.png';

import styles from './page-header.module.css';
import { Image } from 'react-bootstrap';

var authStateHandler = null;

const PageHeader = () => {
    const [currentUser, setCurrentUser] = useState();
    const [roles, setRoles] = useState([]);
    const history = useNavigate();

    useEffect(() => {
        authStateHandler = auth.onAuthStateChanged(user => {
            console.log("auth state changed");
            setCurrentUser(user);
        });
        return () => {
            authStateHandler();
        };
    });

    useEffect(() => {
        if (currentUser) {
            console.log("lookup roles");
            currentUser.getIdTokenResult().then(result => {
                console.log("got roles");
                setRoles(result.claims.roles);
            });
        }
    }, [currentUser])

    const signOut = async () => {
        await auth.signOut();
        history.replace("/");
    }

    const popover = currentUser ? <Popover>
        <Popover.Header>Angemeldet als</Popover.Header>
        <Popover.Body>
            <div>{currentUser.displayName}</div>
            <div>{currentUser.email}</div>
            <div>Rolle: {roles && roles.join(", ")}</div>
        </Popover.Body>
    </Popover> : <Popover></Popover>;

    const a = currentUser ?
        <>
            <Restrict allowed={['user', 'admin']} roles={roles} >
                <Link to="/teams">
                    <div>Teams</div>
                </Link>
                <Link to="/news">
                    <div>News</div>
                </Link>
                <Link to="/tickers">
                    <div>Ticker</div>
                </Link>
            </Restrict>
            <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="bottom-end" overlay={popover} >
                <div onClick={signOut} style={{ cursor: "pointer" }}>Abmelden</div>
            </OverlayTrigger>
        </> :
        <Link to="/signin">
            <div>Anmelden</div>
        </Link>;

    return (
        <div className={styles.pageHeader}>
            <Link to="/">
                <div className={styles.home}><Image src={logo} height={45} /></div>
            </Link>
            {a}
        </div>
    )
}

export default PageHeader
